<div fxLayout="row wrap"
     fxLayoutAlign="space-between center"
     fxLayoutGap="15px"
     class="top-heading text-bolder text-color">
  <div fxFlex>Edit Attendance</div>
  <div fxFlex
       fxLayoutAlign="end center">
    <!-- <button mat-button
            class="editBtn"
            (click)="goToView()">
      View Details
    </button> -->
  </div>
</div>
<form [formGroup]="FormTwo">
  <div fxLayout="row"
       fxLayoutAlign="start start"
       fxLayoutGap="10px">

    <div fxFlex="18">
      <mat-form-field>
        <mat-label>Select Child</mat-label>
        <mat-select class="height-40"
                    [(ngModel)]="childId"
                    required
                    [ngModelOptions]="{standalone: true}"
                    disabled="true">
          <mat-option *ngFor="let child of activeChildrens"
                      [value]="child.id">
            <ng-container>
              {{child?.firstName}} {{child?.lastName}}
            </ng-container>
          </mat-option>
        </mat-select>
        <!-- <mat-error *ngIf="getField('childId')">{{getErrorMessage('childId')}}</mat-error> -->
      </mat-form-field>
    </div>
    <div fxFlex="18">
      <mat-form-field (click)="desired2.open()">
        <mat-label>Select date</mat-label>
        <input matInput
               [max]="currentDate"
               required
               (dateChange)="onDateChange()"
               [matDatepicker]="desired2"
               formControlName="dateValue"
               disabled="true">
        <!-- <mat-error *ngIf="getField('matDateOfBirth')">{{getErrorMessage('matDateOfBirth')}}</mat-error> -->
        <mat-icon matSuffix>
          <img [src]="calendar"
               style="margin-bottom: 0px;margin-right: 10px; width: 16px; vertical-align: baseline;cursor: pointer;">
        </mat-icon>
        <mat-datepicker #desired2></mat-datepicker>
      </mat-form-field>
    </div>

    <div fxFlex="18">
      <mat-form-field>
        <mat-label>Select Room</mat-label>
        <mat-select class="height-40"
                    required
                    [(ngModel)]="roomId"
                    [ngModelOptions]="{standalone: true}"
                    disabled="true">
          <mat-option *ngFor="let option of rooms"
                      [value]="option?.id">
            {{option?.name}} ({{option?.label}})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>
</form>
<div fxLayout="row wrap"
     fxLayoutAlign="center center"
     fxLayoutGap="15px"
     style="background: #ECF2F2 !important;text-align: center;">
  <div fxFlex="13">
    <p class="text-normal-bold heading">
      Session
    </p>
  </div>
  <div fxFlex="15">
    <p class="text-normal-bold heading">
      Add ons
    </p>
  </div>
  <div fxFlex="12">
    <p class="text-normal-bold heading">
      Sign In
    </p>
  </div>
  <div fxFlex="12">
    <p class="text-normal-bold heading"
       style="margin-left: 4%;">
      Sign Out
    </p>
  </div>
  <div fxFlex="38">
    <p class="text-normal-bold heading"
       style="margin-left: 0%;">
      Note
    </p>
  </div>
</div>
<div></div>
<form [formGroup]="Form">
  <mat-card style="padding-top: 0 !important;">
    <div formArrayName="booked_attendance">
      <ng-container [formGroupName]="i"
                    *ngFor="let log of booked_attendance.controls; let i=index">
        <div fxLayout="row wrap"
             fxLayoutAlign="center center"
             fxLayoutGap="15px"
             style="border-bottom: 1px solid lightgrey;min-height: 75px;">
          <div fxFlex="13">
            <span>{{log.get('session').value}}<br>
              <span style="font-size: 12px;"
                    *ngIf="isShowSessionTime">{{log.get('sessionTime').value ? log.get('sessionTime').value : ''}}</span></span>
          </div>
          <div fxFlex="15">
            <span> {{getCommaSeparatedNames(log.get('addOns').value)}}</span>
          </div>
          <div fxFlex="12">
            <timepicker controlName="timeIn"
                        (onSetTime)="onSetTime($event, log, 'matTimeIn')"
                        [disabled]="disableInput"
                        [time]="log.get('matTimeIn').value"
                        [max]="log.get('matTimeOut').value"
                        [showError]="log.get('matTimeIn').invalid"
                        [required]="true"
                        label="Time In"></timepicker>
          </div>
          <div fxFlex="12">
            <timepicker controlName="timeOut"
                        (onSetTime)="onSetTimeSignOut($event, log, 'matTimeOut')"
                        [disabled]="disableInput || !log.get('matTimeIn').value"
                        [time]="log.get('matTimeOut').value"
                        [min]="log.get('matTimeIn').value"
                        [showError]="log.get('matTimeOut').invalid"
                        [required]="true"
                        label="Time Out"></timepicker>
          </div>
          <div fxFlex="38">
            <mat-form-field>
              <mat-label>{{log.get('note').value ? 'Note':'Write a note here'}}</mat-label>
              <input matInput
                     formControlName="note"
                     placeholder="Write a note here">
            </mat-form-field>
          </div>
        </div>

      </ng-container>
    </div>
  </mat-card>
  <div fxLayout="row wrap"
       fxLayoutAlign="end center"
       fxLayoutGap="15px"
       style="margin-top: 10px;">
    <div fxFlex.sm="39"
         fxFlex.xs="100">
      <button (click)="onCancel()"
              type="button"
              mat-button
              style="margin-top: 10px;background-color: #00AFBB;"
              class="btn-custom-form-small">
        Cancel
      </button>
    </div>
    <div fxFlex.sm="39"
         fxFlex.xs="100">
      <button (click)="onSubmit()"
              type="button"
              mat-button
              style="margin-top: 10px;background-color: #E2AF2A;"
              class="btn-custom-form-small"
              [ngStyle]="{'opacity': isUpdateDisabled ? 0.4 : 1 }"
              [disabled]="isUpdateDisabled">
        Update
      </button>
    </div>
  </div>
</form>
