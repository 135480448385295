<div style="padding: 10px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxFlex="35">
            <h2 class="text-color">Live Child Staff Ratio</h2>
        </div>
        <div fxFlex="65">
            <!-- <div fxLayout="row" style="font-weight: 600;" fxLayoutAlign="end center" fxLayoutGap="8px"> -->
                <div style="display: flex; gap: 20px; place-content: end;font-weight: 600;">
                    <div class="inline">
                        <div style="border-radius: 50%;width: 20px;height: 20px;background: #ff96b6;margin: 0;"></div>
                        <div class="ml-10">
                            <span> Under Managed </span>
                        </div>
                    </div>
                    <div class="inline">
                        <div style="border-radius: 50%;width: 20px;height: 20px;background: #ffe27a;margin: 0;"></div>
                        <div class="ml-10">
                            <span> Over Managed </span>
                        </div>
                    </div>
                    <div class="inline">
                        <div style="border-radius: 50%;width: 20px;height: 20px;background: #72dab3;margin: 0;"></div>
                        <div class="ml-10">
                            <span> All Good </span>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </div>

    <mat-card style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div *ngFor="let item of kpiData?.branchList" style="position: relative;" fxFlex="22" fxFlex.md="28" fxFlex.sm="45" fxFlex.xs="98"  class="mt-30">
                <!-- <div class="inline" style="align-items: center;"> -->
                    <!-- <div style="flex: 88;"> -->
                        <mat-card class="rooms" 
                        [ngClass]="{'good': item?.occupencyPoints[0]?.occupencyStatus == 'allGood' || item.occupencyPoints.length == 0, 
                                    'underUse': item?.occupencyPoints[0]?.occupencyStatus == 'overManaged',  
                                    'overUse': item?.occupencyPoints[0]?.occupencyStatus == 'underManaged'}">
                            <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 0;">
                                <div style="font-size: 14px;font-weight: 700;height: 37px;line-height: 22px;" fxFlex="80">
                                     {{ item.name }}
                                </div>
                                <div fxFlex="20" class="end" style="height: 48px;padding-top: 4px;"> 
                                    <mat-icon (click)="getKpiData()" class="cp">refresh</mat-icon>
                                </div>
                                <!-- <mat-divider></mat-divider> -->
                            </div>
                            <mat-divider></mat-divider>
                            <div style="padding-top: 14px" fxLayout="row" fxLayoutAlign="space-between center">
                                <div fxFlex="80"> 
                                    <img width="30" src="assets/images/sdn/staff.svg" alt="">
                                    <span class="ml-10">Staff</span> 
                                </div>
                                <div style="font-size: 22px;font-weight: 700;" class="end" fxFlex="20"> {{ item.occupencyPoints.length == 0 ? '0' : item.occupencyPoints[0].staffCount }}</div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div fxFlex="80">
                                    <img width="30" src="assets/images/sdn/child.svg" alt=""> 
                                    <span class="ml-10">Children</span> 
                                </div>
                                <div style="font-size: 22px;font-weight: 700;" class="end" fxFlex="20"> {{ item.occupencyPoints.length == 0 ? '0' : item.occupencyPoints[0].childCount }} </div>
                            </div>
                        </mat-card>            
                    <!-- </div> -->
                    <!-- <div class="ms" style="height: 190px;width: 44px;background: red;flex: 12;">
                      <div style="position: relative;">
                        <div style="position: absolute;
                        top: -15px;
                        left: 0px;
                        background: #fefefe;
                        width: 36px;
                        height: 36px;
                        border-radius: 30px 0px 0px 34px;"></div>
                        <div style="position: absolute;
                        bottom: -40px;
                        left: 0px;
                        background: #fefefe;
                        width: 36px;
                        height: 36px;
                        border-radius: 30px 0px 0px 34px;"></div>
                        <li>yes</li>
                        <li>no</li>
                      </div>
                    </div> -->
                <!-- </div> -->
    
            </div>
        </div>
    </mat-card>
    


    <!-- <div fxLayout="row" style="font-weight: 600;background: #F8F8F8;padding: 20px;" class="mt-30" fxLayoutAlign="center center">
        <div fxFlex="20" fxFlex.md="28" fxFlex.sm="45" fxFlex.xs="98">
            <div class="inline">
                <div style="border-radius: 50%;width: 20px;height: 20px;background: #ff96b6;margin: 0;"></div>
                <div class="ml-10">
                    <span> Under Managed </span>
                </div>
            </div>
        </div>
        <div fxFlex="20" fxFlex.md="28" fxFlex.sm="45" fxFlex.xs="98">
            <div class="inline">
                <div style="border-radius: 50%;width: 20px;height: 20px;background: #ffe27a;margin: 0;"></div>
                <div class="ml-10">
                    <span> Over Managed </span>
                </div>
            </div>
        </div>
        <div fxFlex="20" fxFlex.md="28" fxFlex.sm="45" fxFlex.xs="98">
            <div class="inline">
                <div style="border-radius: 50%;width: 20px;height: 20px;background: #72dab3;margin: 0;"></div>
                <div class="ml-10">
                    <span> All Good </span>
                </div>
            </div>
        </div>
    </div> -->

</div>
