<form [formGroup]="Form"
      (ngSubmit)="onSubmit()">
  <ng-container>
    <mat-card class="card"
              style="height: 100vh;">
      <div fxLayout="row wrap"
           fxLayoutAlign="space-between center"
           fxLayoutGap="15px"
           class="top-heading text-bolder text-color">
        Session Information

        <button mat-button
                class="editBtn"
                (click)="goToEdit()"
                *ngIf="type == 'view' && editPermit">
          <mat-icon class="editIcon">edit</mat-icon>
          Edit
        </button>
      </div>
      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px"
           class="row-card-margins">
        <div fxFlex.xl="28"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100">
          <mat-form-field>
            <mat-label>Session Name</mat-label>
            <input matInput
                   type="text"
                   formControlName="name"
                   minlength="2"
                   maxlength="22"
                   required>
            <mat-error *ngIf="getField('name')">{{getErrorMessage('name')}}</mat-error>
          </mat-form-field>
        </div>


        <div fxFlex.xl="28"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100">
          <mat-form-field (click)="desired.open()">
            <mat-label style="margin-left: 7px;">Effective From</mat-label>
            <input matInput
                   style="margin-left: 10px;"
                   required
                   formControlName="matEffectiveFrom"
                   [matDatepicker]="desired"
                   readonly>
            <mat-icon matPrefix>
              <img [src]="calendar"
                   style="margin-bottom: 4px; width: 16px; vertical-align: baseline;
                     cursor: pointer;">
            </mat-icon>
            <mat-icon *ngIf="hasValue('matEffectiveFrom')"
                      matSuffix
                      style="position: relative;
                      top: 3px;
                      font-size: 20px;
                      cursor: pointer;"
                      class="closeIcon cp"
                      (click)="clearDate('matEffectiveFrom')">
              close
            </mat-icon>
            <mat-datepicker #desired></mat-datepicker>
            <mat-error *ngIf="getField('matEffectiveFrom')">{{getErrorMessage('matEffectiveFrom')}}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex.xl="28"
             fxFlex.lg="30"
             fxFlex.md="30"
             fxFlex.sm="45"
             fxFlex.xs="100">
          <mat-form-field (click)="desired1.open()">
            <mat-label style="margin-left: 7px;"> Effective To </mat-label>
            <input matInput
                   style="margin-left: 10px;"
                   [disabled]="isDateDisabled"
                   [min]="minDate"
                   formControlName="matEffectiveTo"
                   [matDatepicker]="desired1"
                   readonly>

            <mat-icon matPrefix>
              <img [src]="calendar"
                   style="margin-bottom: 4px;
                     width: 16px; vertical-align: baseline;
                     cursor: pointer;">
            </mat-icon>

            <mat-icon *ngIf="hasValue('matEffectiveTo')"
                      matSuffix
                      style="position: relative;
                      top: 3px;
                      font-size: 20px;
                      cursor: pointer;"
                      class="closeIcon cp"
                      (click)="clearDate('matEffectiveTo')">
              close
            </mat-icon>
            <mat-datepicker #desired1></mat-datepicker>
          </mat-form-field>
        </div>


      </div>
      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px"
           class="top-heading text-small text-gray">
        Pricing Profile
      </div>
      <div fxLayout="row wrap"
           fxLayoutAlign="start center"
           fxLayoutGap="15px">
        <mat-radio-group aria-label="Select an option"
                         formControlName="category">
          <mat-radio-button value="standard"
                            (change)="sessionType($event)">Standard</mat-radio-button>
          <mat-radio-button value="hourly"
                            (change)="sessionType($event)">Hourly</mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf='showDuration'
           style="margin-top: 20px;">
        <div fxLayout="row wrap"
             fxLayoutAlign="start center"
             fxLayoutGap="15px"
             class="top-heading text-small text-gray">
          Session Duration
        </div>
        <div fxLayout="row wrap"
             fxLayoutAlign="start center"
             fxLayoutGap="15px"
             class="row-wrap-margins ">
          <div fxFlex.xl="23"
               fxFlex.lg="25"
               fxFlex.md="25"
               fxFlex.sm="45"
               fxFlex.xs="100">
            <timepicker controlName="startTime"
                        (onSetTime)="onSetTime($event, 'matStartTime')"
                        [disabled]="disableInput"
                        [time]="Form.get('matStartTime').value"
                        [showError]="Form.get('matStartTime').invalid"
                        [required]="true"
                        [screen]="'session'"
                        label="Start Time"></timepicker>
          </div>
          <div fxFlex.xl="23"
               fxFlex.lg="25"
               fxFlex.md="25"
               fxFlex.sm="45"
               fxFlex.xs="100">
            <timepicker controlName="endTime"
                        (onSetTime)="onSetTime($event, 'matEndTime')"
                        [disabled]="disableInput"
                        [time]="Form.get('matEndTime').value"
                        [min]="Form.get('matStartTime').value"
                        [showError]="Form.get('matEndTime').invalid"
                        [required]="true"
                        [screen]="'session'"
                        label="End Time"></timepicker>
          </div>
        </div>
      </div>
      <app-form-foooter *ngIf="type !== 'view'"
                        (backEvent)="goBack()"
                        (saveInfoEvent)="onSubmit()"
                        [footerProps]="footerProps"></app-form-foooter>
    </mat-card>
  </ng-container>
</form>
