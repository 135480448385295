<form [formGroup]="Form">
    <div mat-dialog-title
         style="float: right;
         font-weight: 600;
         cursor: pointer;
         margin: 7px;
         margin-top: -7px;
         color: #777777;
         width: 12px;
         height: 12px;"
         (click)="close()"><mat-icon>close</mat-icon></div>
    <ng-container>
        <div fxLayout="row wrap"
             fxLayoutAlign="center center"
             style="margin-bottom: 20px;"
             class="top-heading text-small text-gray"
             [ngClass]="{'create': title == 'Create'}">
            {{title}} Event
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row wrap"
                fxLayoutAlign="start center"
                fxLayoutGap="15px"
                style="gap: 4px;"
                class="top-heading text-small text-gray row-margin">
                Event Type <span style="color: red">*</span>
           </div>
        <div fxLayout="row wrap"
             fxLayoutAlign="space-between start"
             style="margin-bottom: 0px;"
             class="row-margin">
            <div fxFlex.xl="100"
                 fxFlex.lg="100"
                 fxFlex.md="100"
                 fxFlex.sm="100"
                 fxFlex.xs="100">
                <mat-form-field>
                    <!-- <mat-label>Event Type</mat-label> -->
                    <mat-select class="height-40"
                                formControlName="type"
                                (selectionChange)="typeChange($event.value)">
                        <mat-option value="midTermHolidays">Non Term Time</mat-option>
                        <!-- <mat-option value="endTermHolidays">End of Term</mat-option> -->
                        <mat-option value="bankHolidays">Holiday</mat-option>
                        <mat-option value="notEligibleForStretching">NES Period</mat-option>
                    </mat-select>
                    <mat-error *ngIf="getField('type')">{{getErrorMessage('type')}}</mat-error>
                    <mat-icon matPrefix>
                        <img [src]="bell"
                        style="margin-bottom: 0px;
                        margin-right: 8px; width: 16px; vertical-align: baseline;">
                       </mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div class="check" *ngIf="Form.controls['type'].value== 'bankHolidays'">
            <mat-checkbox formControlName="isDiscountedHoliday"> Discounted </mat-checkbox>
        </div>
        <div fxLayout="row wrap"
                    fxLayoutAlign="start center"
                    fxLayoutGap="15px"
                    style="gap: 4px;"
                    class="top-heading text-small text-gray row-margin">
                    Add Event Description <span style="color: red">*</span>
               </div>
        <div fxLayout="row wrap"
             fxLayoutAlign="space-between start"
             class="row-margin">
            <div fxFlex.xl="50"
                 fxFlex.lg="100"
                 fxFlex.md="100"
                 fxFlex.sm="100"
                 fxFlex.xs="100">
                <mat-form-field>
                    <!-- <mat-label>Add Event Description</mat-label> -->
                    <textarea matInput
                              style="resize: none;"
                              placeholder="Add Event Description"
                              formControlName="description"
                              #description
                              maxlength="300"
                              required
                              >
               </textarea>
               <mat-icon matPrefix>
                <img [src]="Desc"
                style="margin-bottom: 80px;
                margin-right: 8px; width: 16px; vertical-align: baseline;">
               </mat-icon>
               <mat-hint align="end">{{description.value?.length || 0}}/300</mat-hint>
               <mat-error *ngIf="getField('description')">{{getErrorMessage('description')}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row wrap"
             fxLayoutAlign="star start"
             fxLayoutGap="10px"
             class="row-margin">
            <div fxFlex="48">
                <div fxLayout="row wrap"
                    fxLayoutAlign="start center"
                    fxLayoutGap="15px"
                    style="gap: 4px;"
                    class="top-heading text-small text-gray">
                    Start Date <span style="color: red">*</span>
               </div>
                <mat-form-field (click)="desired.open()">
                    <!-- <mat-label>Start Date</mat-label> -->
                    <input matInput
                           readonly
                           [min]="minDate"
                           [max]="maxDate"
                           formControlName="startDate"
                           [matDatepicker]="desired">
                           <mat-icon matPrefix>
                            <img [src]="event_clander"
                            style="margin-bottom: 2px;
                            margin-right: 8px; width: 16px; vertical-align: baseline;">
                           </mat-icon>
                    <mat-datepicker #desired></mat-datepicker>
                    <mat-error *ngIf="getField('startDate')">{{getErrorMessage('startDate')}}</mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="48">
                <div fxLayout="row wrap"
                fxLayoutAlign="start center"
                fxLayoutGap="15px"
                style="gap: 4px;"
                class="top-heading text-small text-gray">
                End Date <span style="color: red">*</span>
           </div>
                <mat-form-field (click)="desired1.open()">
                    <!-- <mat-label>End Date</mat-label> -->
                    <input matInput
                           readonly
                           [min]="Form.get('startDate').value"
                           [max]="maxDate"
                           formControlName="endDate"
                           [matDatepicker]="desired1">
                           <mat-icon matPrefix>
                            <img [src]="event_clander"
                            style="margin-bottom: 2px;
                            margin-right: 8px; width: 16px; vertical-align: baseline;">
                           </mat-icon>
                    <mat-datepicker #desired1></mat-datepicker>
                    <mat-error *ngIf="getField('endDate')">{{getErrorMessage('endDate')}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row wrap"
             fxLayoutAlign="center center"
             fxLayoutGap="10px">
            <div fxFlex="150px">
                <button (click)="onSubmit()"
                        type="button"
                        mat-button
                        class="btn-custom-form">
                    {{title}} Dates
                </button>
            </div>
        </div>

    </ng-container>
</form>
