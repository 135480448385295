<div fxLayout="row wrap"
     fxLayoutAlign="space-between center"
     class="top-div"
     fxFlexFill
     style="background-image: url(assets/images/Bg_with_plants.png);">
     <div fxFlex></div>
     <div fxFlex.xl="35"
          fxFlex.lg="35"
          fxFlex.md="40"
          fxFlex.sm="60">
          <div style="text-align: center;">
               <img class="logoImage"
                    src="assets/images/logo-green-greener.png">
          </div>
          <form [formGroup]="Form">
               <mat-card>
                    <div fxLayout="column"
                         fxLayoutAlign="space-around center"
                         class="form-group ">
                         <div fxFlex="100"
                              [ngStyle.xs]="{'font-size.px': 20}"
                              [ngStyle.sm]="{'font-size.px': 25}"
                              style="margin:10px 0px 10px"
                              class="bolder-text">
                              <b>
                                   {{bolderText}}
                              </b>
                         </div>
                         <div fxFlex
                              style="margin-top: 30px ;"
                              class="lighter-text">
                              <span><i [class]='iconClass'></i> {{lighterText}}</span>
                         </div>
                         <div fxFlex
                              class="lighter-text">
                              {{lighterText2}}
                         </div>
                    </div>
                    <div fxLayout="row wrap"
                         fxLayoutAlign="center center"
                         style="margin-top: 10px;">
                         <div fxFlex.lg="80"
                              fxFlex.sm="100">
                              <mat-form-field style="width: 100%;">
                                   <mat-label>Enter Email Address</mat-label>
                                   <input matInput
                                          type="text"
                                          formControlName="email"
                                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                          required>
                                   <mat-icon matPrefix>
                                        <img [src]="emailIcon"
                                             style="margin-bottom: 6px;margin-right: 20px;">
                                   </mat-icon>
                              </mat-form-field>
                         </div>
                    </div>

                    <div class="row wrap">
                         <div fxFlex="100%"
                              fxLayoutAlign="center end">
                              <button type="button"
                                      mat-button
                                      (click)="onSubmit()"
                                      class="btn-custom">Proceed</button>
                         </div>
                    </div>
               </mat-card>
          </form>
     </div>
     <div fxFlex></div>
</div>