<div class="dialog-container">
    <mat-icon [ngStyle]="{ 'color': '#119143' }" class="icon-class" style="font-size: 25px; cursor: pointer; ">check</mat-icon>
    <!--  'close' icon for error -->
</div>
<hr>
<div style="text-align: center;" *ngIf="!isSplitInvoice">
    <p>Invoice no {{invoice?.id}} rolled back and credit note 
        <a mat-dialog-close routerLink={{redirectUrl}} >{{creditlist[0]?.serializeCreditId}}</a> 
        generated against the invoice</p>
</div>
<div style="text-align: center;" *ngIf="isSplitInvoice">
    <p>Invoice no {{invoice?.id}} and {{splitInvoice?.id}} rolled back and credit note 
         <a mat-dialog-close routerLink={{redirectUrl}}>{{creditlist[0]?.serializeCreditId}}</a> and  <a mat-dialog-close routerLink={{redirectUrl}}>{{creditlist[1]?.serializeCreditId}}</a> generated against the invoice </p>
</div>
<div class="btn-container">
      <button  
            (click)="onOk()"
            class="btn-confirm">
            Ok
      </button>
</div>
