<mat-card class="card">
  <div fxLayout="row wrap"
       fxLayoutAlign="center center">
    <div fxLayout="row wrap"
         fxLayoutAlign="space-between center"
         fxFlex="100">

      <div style="font-weight: 600;">
        <div class="top-heading text-bolder mt-0 text-color">
          Summary
        </div>
      </div>
      <button mat-button
              class="editBtn"
              (click)="goToEdit()"
              *ngIf="type == 'view' && editPermit && !formDetail?.childOffboardingStatus">
        <mat-icon class="editIcon">edit</mat-icon>
        Edit
      </button>
    </div>
    <div fxFlex="100">
      <div class="room-detail">
        <div class="top-heading text-bolder mt-0 ">
          {{formDetail.firstName? formDetail.firstName : ''}} {{formDetail.lastName? formDetail.lastName : ''}}
        </div>
        <div style="font-weight: 600;">
          <p class=" text-bolder mt-0 "
             style="margin-bottom: 0px">
            Offboarded by
          </p>
          <p style="font-size: 10px;margin-bottom: 0px;">
            {{user.name ? user.name : user.firstName}} {{user.name ? '' :  user.lastName }}
            ({{staffInfo? staffInfo.staffRole :''}})
            <span *ngIf="type != 'view'">
              {{ time | date: 'dd/MM/yyyy hh:mm:ss a' }}
            </span>
            <span *ngIf="type == 'view'">
              {{offboardingDetails.updatedDate | date: 'dd/MM/yyyy hh:mm:ss a'}}
            </span>
          </p>
        </div>
      </div>
    </div>
    <div fxFlex="100"
         class="mx-3">
      <div class="room-detail"
           fxFlex="50">
        <div style="font-weight: 600;">
          <p class="mb-1 summary-header">Leave date</p>
          <p class="mt-0">{{leaveDate}}</p>
        </div>

        <div style="font-weight: 600;"
             fxFlex="50">
          <p class="mb-1 summary-header">Notice Given</p>
          <p class="mt-0">{{noticeGiven}}</p>
        </div>
      </div>
    </div>
    <div fxFlex="100"
         class="mx-3">
      <div class="room-detail">
        <div style="font-weight: 600;">
          <p class="mb-1 summary-header">Reason</p>
          <p class="mt-0">{{previousForm?.reason}}</p>
        </div>
      </div>
    </div>
  </div>
  <div style="color:red;font-size: 14px;"
       class="mt-3"
       *ngIf="staffInfo?.siblingName">
    <p class="mb-1 summary-header"
       style="font-weight: 600;">Note</p>
    <p class="mt-0">This child's sibling "<span
            style="font-weight: bold;">{{staffInfo? staffInfo.siblingName :''}}</span>" won't be geting siblings
      discount
      after the set leave date.
    </p>
  </div>
  <div fxLayout="row wrap"
       fxLayoutAlign="start center"
       fxLayoutGap="5px"
       style="height:100px">
    <div fxFlex="20">
      <button mat-button
              style="width: 90%;"
              type="button"
              class="btn-custom-form"
              (click)="onCancel()">Cancel</button>
    </div>

    <div fxFlex="20">
      <button mat-button
              style="width: 90%;background-color: rgb(193, 187, 185);"
              type="button"
              class="btn-custom-form "
              (click)="goBack()">Go Back</button>
    </div>
    <div fxFlex="30"
         *ngIf="!formDetail?.childOffboardingStatus && type !='view'">
      <button mat-button
              style="width: 90%;background-color: rgb(226, 175, 42);"
              class="btn-custom-form yellow"
              type="button"
              (click)="onUpdateOffboarding()">Confirm Offboarding</button>
    </div>

  </div>
</mat-card>
