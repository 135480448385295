<mat-card class="row-margin-tb card-dark">
    <mat-card >
        <app-app-headers [headerProps]="buttonHeaderProps" (outPutHeaders)="headerOutputAction($event)"></app-app-headers>
      </mat-card>
      
  <mat-card style="background-color: #ffffff26 !important; border: 0px !important;">
    <p class="text-normal" style="color: #4A4A4A; line-height: 8px;">
     {{headerProps.searchConfig.label}} <span style="color: red">*</span>
    </p> 
     <!-- <filter-control 
         [hasButton]="true"
         [hasClearButton]="true"
         [layoutAlign]="layoutAlign"
         (filnalFilters)="filnalFilters($event)"
         [hasSearch]="true"
         [hasDatePicker]="true"
         [searchConfig]="headerProps?.searchConfig"
         [builtInFilters]="headerProps?.builtInFilters"
         [filterArray]="headerProps?.filterArray"></filter-control> -->
         <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" >

            <div fxFlex="50"
                 fxFlex.md="40"
                 fxFlex.sm="30"
                 fxFlex.xs="100">
                <mat-form-field class="example-chip-list">
                    <mat-chip-list #chipList aria-label="Fruit selection">
                      <mat-chip
                        *ngFor="let staff of selectedStaffs"
                        [selectable]="selectable"
                        [removable]="removable"
                        (removed)="remove(staff)">
                        {{staff.name}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip>
                      <input
                        placeholder="Search Staff..."
                        required
                        #fruitInput
                        [formControl]="fruitCtrl"
                        [matAutocomplete]="auto"
                        [disabled]="isSelectAll"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (ngModelChange)="checkforValue($event)"
                        (click)="loadData()"
                        (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                      <mat-option *ngFor="let staff of filteredStaff | async" [value]="staff">
                        {{staff.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            </div>
            <div fxFlex="100px" style="padding-top: 9px;">
             <mat-checkbox style="margin-top: 5px;" [(ngModel)]="isSelectAll" (ngModelChange)="checkAllStaff()">Select All</mat-checkbox>
            </div>
            <div fxFlex="30"
                 fxFlex.md="25"
                 fxFlex.sm="20"
                 fxFlex.xs="100">
                <mat-form-field >
                    <mat-label>Select Date Range <span style="color: red;">*</span></mat-label>
                    <mat-date-range-input 
                               (click)="FormPicker.open()"
                               [rangePicker]="FormPicker">
                        <input matStartDate
                               readonly
                               (dateChange)="valueChanged2()"
                               placeholder="Start date"
                               [(ngModel)]="startDate">
                        <input matEndDate
                               readonly
                               (dateChange)="valueChanged2()"
                               placeholder="End date"
                               [(ngModel)]="endDate">
                    </mat-date-range-input>
                    <mat-icon style="cursor: pointer;"
                              *ngIf="startDate && endDate"
                              matSuffix
                              (click)="onClear()">close</mat-icon>
                    <mat-datepicker-toggle matPrefix
                                           [for]="FormPicker"></mat-datepicker-toggle>
                    <mat-date-range-picker (closed)="onClosed()"
                                           #FormPicker></mat-date-range-picker>
                </mat-form-field>
            </div>
            <!-- <div fxFlex="100px">
                <button type="button"
                        mat-button
                        class="btn-custom-form"
                        style="width: 100px;margin-bottom: 6px;">
                    Search
                </button>
            </div> -->
            <div fxFlex="100px">
                <button type="button"
					mat-button
					class="btn-custom-form"
                    style="background: #e8e8e8"
					(click)="onClear()"
					style="width: 100px;margin-bottom: 6px;">
				Clear
			</button>
            </div>
            
         </div>
   </mat-card>


  
  <!-- <app-table-component (onPagination)="onPagination($event)"
                     (selectedItem)="actionButtonOutput($event)"
                     [props]="tableConfigAndProps"></app-table-component> -->
  
    </mat-card>