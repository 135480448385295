<div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="15px" class="top-heading text-bolder text-color">
    
    <div fxFlex>Edit Attendance</div>
    <div fxFlex fxLayoutAlign="end center"> 
        <button mat-button class="editBtn" (click)="goToView()">
            View Details
        </button>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center" class="top-heading text-bold">
    {{subTitle}}
  </div>

<div fxLayout="row wrap"
     fxLayoutAlign="center center"
     fxLayoutGap="15px"
     style="background: #ECF2F2 !important;text-align: center;padding-right:45px;">

    <div fxFlex="15">
        <p class="text-normal-bold" style="margin-top: 10px;text-align: left;">
        Branch Name
        </p>
    </div>

    <div fxFlex="15">
        <p class="text-normal-bold heading">
        Sign in
        </p>
    </div>

    <div fxFlex="15">
        <p class="text-normal-bold heading">
        Break Type
        </p>
    </div>

    <div fxFlex="15">
        <p class="text-normal-bold heading">
        Break Start
        </p>
    </div>

    <div fxFlex="15">
        <p class="text-normal-bold heading">
        Break End
        </p>
    </div>

    <div fxFlex="15">
        <p class="text-normal-bold heading">
        Sign Out
        </p>
    </div>
</div>

<div>

</div>


<form [formGroup]="Form">
    <mat-card style="padding-top: 0 !important;">

        <div formArrayName="attendanceLogs">

            <ng-container [formGroupName]="i" *ngFor="let log of attendancelogs.controls; let i=index">
                <div fxLayout="row wrap"
                fxLayoutAlign="start center"
                fxLayoutGap="15px"
                
                style="border-bottom: 1px solid lightgrey;height: 75px;"
                >
               
                   <div fxFlex="15">
                        <p class="text-normal-bold" style="margin-top: 10px;">
                        {{ log.get('branch')?.value?.name }}
                        </p>
                   </div>
        
                   <div fxFlex="15">
                    <timepicker controlName="timeIn" (onSetTime)="onSetTime($event, log, 'matTimeIn')" [disabled]="disableInput"
                    [time]="log.get('matTimeIn').value" [max]="log.get('matTimeOut').value" [showError]="log.get('matTimeIn').invalid"
                    [required]="true" label="Time In"></timepicker>
                   </div>

                   <div fxFlex="15">
                    <mat-form-field style="margin-top: 5px;">
                        <mat-label>Select Break Type</mat-label>
                           <mat-select formControlName="breakType">
                               <mat-option value="offPremises"> Off Premises </mat-option>
                               <mat-option value="onPremises"> On Premises </mat-option>
                           </mat-select>
                       <!-- <mat-error *ngIf="getField('duration', staffProbationPeriodDetail)">{{getErrorMessage('duration')}}</mat-error> -->
                        </mat-form-field>
                    </div>
        
                    <div fxFlex="15">
                        <timepicker controlName="breakIn" (onSetTime)="onSetTime($event, log, 'matBreakIn')" [disabled]="disableInput"
                                    [time]="log.get('matBreakIn').value" [max]="log.get('matBreakOut').value" [showError]="log.get('matBreakIn').invalid"
                                    label="Break In"></timepicker>
                    </div>
                    <div fxFlex="15">
                        <timepicker controlName="breakOut" (onSetTime)="onSetTimeBreakOut($event, log, log.get('matTimeOut').value, 'matBreakOut')" [disabled]="disableInput"
                                    [time]="log.get('matBreakOut').value" [min]="log.get('matBreakIn').value" [showError]="log.get('matBreakOut').invalid"
                                    label="Break Out"></timepicker>
                    </div>
        
                    <div fxFlex="15">
                        <timepicker controlName="timeOut" (onSetTime)="onSetTimeSignOut($event, log, log.get('matBreakOut').value, 'matTimeOut')" [disabled]="disableInput || !log.get('matTimeIn').value"
                        [time]="log.get('matTimeOut').value" [min]="log.get('timeIn').value" [showError]="log.get('matTimeOut').invalid"
                        [required]="true" label="Time Out"></timepicker>
                    </div>
                </div>

                <div *ngIf="log.get('attendanceBreaksLogs').controls.length !=0" formArrayName="attendanceBreaksLogs">
                    <ng-container [formGroupName]="j" *ngFor="let breakData of log.get('attendanceBreaksLogs').controls; let j=index">
                        <div fxLayout="row wrap"
                        fxLayoutAlign="start center"
                        fxLayoutGap="15px"
                        *ngIf="j != 0"
                    
                        style="border-bottom: 1px solid lightgrey;height: 75px;"
                        >
                       
                            <div fxFlex="15">
                                    <p class="text-normal-bold" style="margin-top: 10px;">
                                    
                                    </p>
                            </div>
                
                            <div fxFlex="15">
                                    <p class="text-normal-bold" style="margin-top: 10px;">
                                        
                                    </p>
                            </div>
                            <div fxFlex="15">
                                <mat-form-field style="margin-top: 5px;">
                                    <mat-label>Select Break Type</mat-label>
                                       <mat-select formControlName="breakType">
                                           <mat-option value="offPremises"> Off Premises </mat-option>
                                           <mat-option value="onPremises"> On Premises </mat-option>
                                       </mat-select>
                                   <!-- <mat-error *ngIf="getField('duration', staffProbationPeriodDetail)">{{getErrorMessage('duration')}}</mat-error> -->
                                    </mat-form-field>
                                </div>
                
                                <div fxFlex="15">
                                    <timepicker controlName="breakIn" (onSetTime)="onSetTime($event, breakData, 'matBreakIn')" [disabled]="disableInput"
                                    [time]="breakData.get('matBreakIn').value" [max]="breakData.get('matBreakOut').value" [showError]="breakData.get('matBreakIn').invalid"
                                    [required]="true" label="Break In"></timepicker>
                                </div>
                                <div fxFlex="15">
                                    <timepicker controlName="breakOut" (onSetTime)="onSetTimeBreakOut($event, breakData, log.get('matTimeOut').value, 'matBreakOut')" [disabled]="disableInput"
                                    [time]="breakData.get('matBreakOut').value" [min]="breakData.get('matBreakIn').value" [max]="log.get('matTimeOut').value" [showError]="Form.get('matBreakOut').invalid"
                                    [required]="true" label="Break Out"></timepicker>
                                </div>
                
                                <div fxFlex="15">
                                    <p class="text-normal-bold" style="margin-top: 10px;">
                                    
                                    </p>
                                </div>
                            </div>
                    </ng-container>
                </div>              
            </ng-container>        
       </div>
    </mat-card>
  </form>

  <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="15px" style="margin-top: 10px;">
    <div fxFlex.sm="39" fxFlex.xs="100">
      <button (click)="onCancel()" style="background-color: #e8e8e8;" type="button" mat-button style="margin-top: 10px;" class="btn-custom-form-small">
        Cancel
      </button>
    </div>
    <div fxFlex.sm="39" fxFlex.xs="100">
      <button (click)="onSubmit()" type="button" mat-button style="margin-top: 10px;" class="btn-custom-form-small">
        Update
      </button>
    </div>
  </div>
  