<form [formGroup]="Form"
      (ngSubmit)="onSubmit()">
    <mat-card class="card">
        <ng-container formArrayName="guardianDetails"
                      *ngFor="let item of Form.get('guardianDetails')['controls']; let i = index;">
            <ng-container [formGroupName]="i">
                <div fxLayout="row wrap"
                     fxLayoutAlign="space-between center"
                     fxLayoutGap="15px"
                     class="top-heading text-bolder text-color">
                    <span *ngIf="i < 1">Primary Guardian Details</span>
                    <span *ngIf="i > 0">Secondary Guardian Details</span>
                    <button mat-button class="editBtn" (click)="goToEdit()" *ngIf="type == 'view' && editPermit">
                        <mat-icon class="editIcon">edit</mat-icon>
                        Edit
                    </button>
                </div>
                <div fxLayout="row wrap"
                     fxLayoutAlign="space-between center"
                     class="row-wrap-margins">
                    <div fxFlex.xl="28"
                         fxFlex.lg="26"
                         fxFlex.md="26"
                         fxFlex.sm="45"
                         fxFlex.xs="100"
                         class="col-flex-margins">
                        <mat-form-field>
                            <mat-label>Title</mat-label>
                            <mat-select class="height-40"
                                        formControlName="title" required>
                                <mat-option value="mr">Mr.</mat-option>
                                <mat-option value="mrs">Mrs.</mat-option>
                                <mat-option value="ms">Ms.</mat-option>
                                <mat-option value="miss">Miss</mat-option>
                            </mat-select>
                            <mat-error *ngIf="getField('title', item)">{{getErrorMessage('title')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex.xl="28"
                         fxFlex.lg="38"
                         fxFlex.md="38"
                         fxFlex.sm="45"
                         fxFlex.xs="100"
                         class="col-flex-margins">
                        <mat-form-field>
                            <mat-label>Name</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="name"
                                   minlength="2"
                                   maxlength="36"
                                   required>
                                   <mat-error *ngIf="getField('name', item)">{{getErrorMessage('name')}}</mat-error>
                        </mat-form-field>
                        <!-- <mat-form-field style="margin-top: 10px;">
                            <input type="text"
                            placeholder="Name *"
                            aria-label="Number"
                            matInput
                            [formControl]="Form.get('name')"
                            [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption (optionSelected)="setChildData()" #auto="matAutocomplete">
                              <mat-option *ngFor="let option of filteredChildrens" [value]="option">
                                {{option.firstName}}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="getField('name', item)">{{getErrorMessage('name')}}</mat-error>
                          </mat-form-field> -->
                    </div>
                    <div fxFlex.xl="28"
                         fxFlex.lg="32"
                         fxFlex.md="32"
                         fxFlex.sm="45"
                         fxFlex.xs="100"
                         class="col-flex-margins">
                        <mat-form-field>
                            <mat-label>Relationship to the child </mat-label>
                            <mat-select formControlName="relationToChild" required>
                                <mat-option value="father">Father</mat-option>
                                <mat-option value="mother">Mother</mat-option>
                                <mat-option value="grandFather">Grandfather</mat-option>
                                <mat-option value="grandMother">Grandmother</mat-option>
                                <mat-option value="sibling">Sibling</mat-option>
                                <mat-option value="uncle">Uncle</mat-option>
                                <mat-option value="aunt">Aunt</mat-option>
                                <mat-option value="cousin">Cousin</mat-option>
                                <mat-option value="other">Other</mat-option>
                            </mat-select>
                            <mat-error *ngIf="getField('relationToChild', item)">{{getErrorMessage('relationToChild')}}</mat-error>
                        </mat-form-field>

                        <!-- <mat-form-field>
                            <mat-label>Relationship to the child</mat-label>
                            <input type="text"
                                  aria-label="Number"
                                  matInput
                                  required
                                  [formControl]="item.get('relationLabel')"
                                  [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="setRelationValue(item)">
                              <mat-option *ngFor="let option of item.get('filteredRelations').value" [value]="option.label">
                                {{option.label}}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="getField('relationToChild', item)">{{getErrorMessage('relationToChild', item)}}</mat-error>
                        </mat-form-field> -->
                        
                    </div>
                    <div fxFlex.xl="28"
                         fxFlex.lg="32"
                         fxFlex.md="32"
                         fxFlex.sm="45"
                         fxFlex.xs="100"
                         class="col-flex-margins">
                        <mat-form-field (click)="desired.open()">
                            <mat-label>Date Of Birth</mat-label>
                            <input matInput
                                   (dateChange)="setDateFormat(item,'dateOfBirth',$event)"
                                   [min]="minDate"
                                   [max]="currentDate"
                                   formControlName="matDateOfBirth"
                                   [matDatepicker]="desired">
                            <mat-icon matSuffix>
                                <img [src]="calendar"
                                     style="margin-bottom: 0px;
                                    margin-right: 10px; width: 16px; vertical-align: baseline;
                                    cursor: pointer; filter: invert(51%) sepia(6%);">
                            </mat-icon>
                            <mat-datepicker #desired></mat-datepicker>
                            <mat-error *ngIf="getField('matDateOfBirth', item)">{{getErrorMessage('matDateOfBirth')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex.xl="28"
                         fxFlex.lg="66"
                         fxFlex.md="66"
                         fxFlex.sm="45"
                         fxFlex.xs="100"
                         class="col-flex-margins">
                        <mat-form-field>
                            <mat-label>Address</mat-label>
                            <input matInput
                                   readonly
                                   type="text"
                                   (click)="openAddressDialog(item)"
                                   formControlName="addressLabel"
                                   required>
                            <mat-error *ngIf="getField('address', item)">{{getErrorMessage('address')}}</mat-error>
                            <span *ngIf="item.get('address').value && !disableInput"
                                  (click)="removeAddress(item)" 
                                  class="removeAddress">Remove</span>
                            <mat-icon matSuffix>
                                <img [src]="location"
                                     style="margin-bottom: 0px;
                                margin-right: 10px; width: 14px; vertical-align: baseline;">
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div fxFlex.xl="28"
                         fxFlex.lg="32"
                         fxFlex.md="32"
                         fxFlex.sm="45"
                         fxFlex.xs="100"
                         class="col-flex-margins">

                        <!-- <mat-form-field>
                            <mat-label>Primary Language</mat-label>
                            <mat-select formControlName="firstLanguageId">
                                <mat-option *ngFor="let item of firstLanguages" [value]="item.id"> {{ item.language }} </mat-option>
                              </mat-select>
                            <mat-error *ngIf="getField('firstLanguageId', item)">{{getErrorMessage('firstLanguage')}}</mat-error>
                        </mat-form-field> -->

                        <mat-form-field>
                            <mat-label>Primary Language</mat-label>
                            <input type="text"
                            aria-label="Number"
                            matInput
                            required
                            [formControl]="item.get('languageLabel')"
                            [matAutocomplete]="auto3">
                            <mat-autocomplete autoActiveFirstOption #auto3="matAutocomplete" (optionSelected)="setLanguageId(item)">
                              <mat-option *ngFor="let option of item.get('filteredLanguage').value" [value]="option.language">
                                {{option.language}}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="getField('languageLabel')">{{getErrorMessage('languageLabel')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex.xl="28"
                         fxFlex.lg="32"
                         fxFlex.md="32"
                         fxFlex.sm="45"
                         fxFlex.xs="100"
                         class="col-flex-margins">
                        <mat-form-field>
                            <mat-label>National Insurance No.</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="nationalInsuranceNumber"
                                   placeholder="XX123456Q">
                                   <mat-error *ngIf="getField('nationalInsuranceNumber', item)">{{getErrorMessage('nationalInsuranceNumber')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex.xl="28"
                         fxFlex.lg="32"
                         fxFlex.md="32"
                         fxFlex.sm="45"
                         fxFlex.xs="100"
                         class="col-flex-margins">
                        <mat-form-field>
                            <mat-label>Home Landline No.</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="homeLandLineNumber"
                                   >
                                   <mat-error *ngIf="getField('homeLandLineNumber', item)">{{getErrorMessage('homeLandLineNumber')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex.xl="28"
                         fxFlex.lg="32"
                         fxFlex.md="32"
                         fxFlex.sm="45"
                         fxFlex.xs="100"
                         class="col-flex-margins">
                        <mat-form-field>
                            <mat-label>Mobile No.</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="mobileNumber"
                                   required>
                                   <mat-error *ngIf="getField('mobileNumber', item)">{{getErrorMessage('mobileNumber')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex.xl="28"
                         fxFlex.lg="32"
                         fxFlex.md="32"
                         fxFlex.sm="45"
                         fxFlex.xs="100"
                         class="col-flex-margins">
                        <mat-form-field>
                            <mat-label>Work Telephone No.</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="workTelephoneNumber"
                                   >
                                   <mat-error *ngIf="getField('workTelephoneNumber', item)">{{getErrorMessage('workTelephoneNumber')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex.xl="28"
                         fxFlex.lg="32"
                         fxFlex.md="32"
                         fxFlex.sm="45"
                         fxFlex.xs="100"
                         class="col-flex-margins">
                        <mat-form-field>
                            <mat-label>Email Address</mat-label>
                            <input matInput
                                   type="text"
                                   formControlName="email"
                                   required>
                                   <mat-error *ngIf="getField('email', item)">{{getErrorMessage('email')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex.xl="28"
                         fxFlex.lg="32"
                         fxFlex.md="32"
                         fxFlex.sm="45"
                         fxFlex.xs="100">
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <app-form-foooter *ngIf="type!='view'"
                          (saveInfoEvent)="addMore()"
                          (backEvent)="goBack()"
                          [footerProps]="footerProps"></app-form-foooter>
    </mat-card>

</form>
