<div *ngIf="!isSplitInvoice">
      <div class="dialog-container">
            <mat-icon [ngStyle]="{ 'color': '#FFAB00' }" class="icon-class" style="font-size: 25px; cursor: pointer; vertical-align: middle;">error_outline</mat-icon>
      </div>
      <hr>
      <div style="text-align: center; margin-bottom: 30px;">
            <span id="invoice-title">Invoice {{invoice?.id}}</span>
            <p style="margin-top: 10px;">Invoice {{invoice?.id}} with the below details will be rolled back</p>
      </div>

      <div style="text-align: center; padding-left: 50px; padding-right: 50px;">
            <div class="detail-item">
                  <span class="details-heading">Invoice ID:</span>
                  <p>{{invoice?.id}}</p>
            </div>
            <div class="detail-item">
                  <span class="details-heading">Parent:</span>
                  <p>{{invoice?.guardian?.name}}</p>
            </div>
            <div class="detail-item">
                  <span class="details-heading">Child(ren):</span>
                  <p>{{invoice?.childNames}}</p>
            </div>
      </div>
      <div class="btn-container">
            <button
            (click)="onCancel()"
            class="btn-cancel">
                  Cancel
            </button>
            <button  
                  (click)="onConfirm()"
                  class="btn-confirm">
                  Confirm
            </button>
      </div>
</div>

<div *ngIf="isSplitInvoice">
      <div class="dialog-container">
            <mat-icon [ngStyle]="{ 'color': '#FFAB00' }" class="icon-class" style="font-size: 25px; cursor: pointer; vertical-align: middle;">error_outline</mat-icon>
      </div>
      <hr>
      <div style="text-align: center; margin-bottom: 30px;">
            <span id="invoice-title">Invoice {{invoice?.id}}</span>
            <p style="margin-top: 10px;">Invoice {{invoice?.id}} with the below details will be rolled back</p>
      </div>

      <div class="row" >      
            <div class="col-6" style="padding-left: 50px">
                  <div class="detail-item">
                        <span class="details-heading">Invoice ID:</span>
                        <p>{{invoice?.id}}</p>
                  </div>
                  <div class="detail-item">
                        <span class="details-heading">Parent:</span>
                        <p>{{invoice?.guardian?.name}}</p>
                  </div>
                  <div class="detail-item">
                        <span class="details-heading">Child(ren):</span>
                        <p>{{invoice?.childNames}}</p>
                  </div>
            </div>
            <div class="col-6" style="padding-right: 50px">
                  <div class="detail-item">
                        <span class="details-heading">Invoice ID:</span>
                        <p>{{splitInvoice?.id}}</p>
                  </div>
                  <div class="detail-item">
                        <span class="details-heading">Parent:</span>
                        <p>{{splitInvoice?.guardian?.name}}</p>
                  </div>
                  <div class="detail-item">
                        <span class="details-heading">Child(ren):</span>
                        <p>{{splitInvoice?.childNames}}</p>
                  </div>
            </div>
      </div>
      <div class="btn-container">
            <button
            (click)="onCancel()"
            class="btn-cancel">
                  Cancel
            </button>
            <button  
                  (click)="onConfirm()"
                  class="btn-confirm">
                  Confirm
            </button>
      </div>
</div>
