<div fxLayout="row wrap"
     style="margin-bottom: 20px;"
     fxLayoutAlign="space-between center">
    <div fxFlex
         class="top-heading"
         style="font-weight: bold;">
        <span class="header-top-text-bolder">
            {{props?.headingLabel}}
        </span>
    </div>
    <div fxFlex="28">
        <button class="header-button"
                (click)="onTableHeaderButton()"
                mat-button>{{props?.buttonLabel}}</button>
    </div>
</div>