<div id="outerContainer">
    <div class="icon-container">
        <mat-icon (click)="zoomOut()"
                  class="close cp">zoom_out</mat-icon>
        <mat-icon (click)="zoomIn()"
                  class="close cp">zoom_in</mat-icon>
        <mat-icon mat-dialog-close
                  class="close cp">close</mat-icon>
    </div>
    <div class="pdf-container">
        <pdf-viewer [src]="data?.url?.changingThisBreaksApplicationSecurity"
                    [rotation]="0"
                    [original-size]="false"
                    [show-all]="true"
                    [fit-to-page]="false"
                    [zoom]="zoom"
                    [zoom-scale]="'page-width'"
                    [stick-to-page]="false"
                    [render-text]="true"
                    [external-link-target]="'blank'"
                    [autoresize]="true"
                    [show-borders]="false"
                    style="width: 100%; height: 100vh;"></pdf-viewer>
    </div>
</div>