import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
    selector: 'app-pdf-view',
    templateUrl: './pdf-view.component.html',
    styleUrls: ['./pdf-view.component.scss']
})
export class PdfViewComponent implements OnInit, OnDestroy 
{
    pdfUrl: SafeResourceUrl;
    data: any;
    zoom: number = 1;
    constructor(protected sanitizer: DomSanitizer) { }

    ngOnInit(): void
    {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
        window.addEventListener('wheel', this.onWheel.bind(this), { passive: false });
    }

    ngOnDestroy()
    {
        window.removeEventListener('wheel', this.onWheel.bind(this));
    }

    zoomIn()
    {
        this.zoom += 0.1;
    }

    zoomOut()
    {
        if (this.zoom > 0.1)
        {
            this.zoom -= 0.1;
        }
    }

    onWheel(event: WheelEvent)
    {
        if (event.ctrlKey)
        {
            event.preventDefault();
            if (event.deltaY < 0)
            {
                this.zoomIn();
            } else
            {
                this.zoomOut();
            }
        }
    }
}
