<div class="dialog-container">
    <mat-icon [ngStyle]="{ 'color': '#FFAB00' }" class="icon-class" style="font-size: 25px; cursor: pointer; vertical-align: middle;">error_outline</mat-icon>
</div>
<hr>
<div style="text-align: center;">
    <span id="invoice-title">Invoice No {{invoice?.id}} and {{splitInvoice?.id}}</span>
    <p>Invoice No {{invoice?.id}} is associated with Invoice No {{splitInvoice?.id}} due to a split payment arrangement between two parents</p>
    <p style="margin-top: 5%;">Would you like to roll back both Invoices ?</p>
</div>
<div class="btn-container">
    <button
       (click)="onCancel()"
       class="btn-cancel">
            Cancel
      </button>
      <button  
            (click)="onConfirm()"
            class="btn-confirm">
            Confirm
      </button>
</div>
