<mat-icon mat-dialog-close class="close cp">close</mat-icon>

<div class="top-heading text-bolder text-color mb-20"> 
    Advanced Settings
</div>

<div class="top-heading text-bold" style="margin-top: 13px">
    Funding, Discounts and Add-ons
</div>

<div style="overflow-x: auto;" class="mt-30">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mat">


        <ng-container matColumnDef="day">
          <th mat-header-cell *matHeaderCellDef> Day </th>
          <td mat-cell *matCellDef="let element"> {{ element.day | titlecase }} <br *ngIf="bookingType != 'recurring_sessions'"> <span *ngIf="bookingType != 'recurring_sessions'">({{element.sessionDate | date: 'dd MMM yyyy'}})</span>  </td>
        </ng-container>

      
        <ng-container matColumnDef="room">
          <th mat-header-cell *matHeaderCellDef> Room </th>
          <td mat-cell *matCellDef="let element;let i = index;"> 

            {{ element?.roomName }}
          </td>
        </ng-container>

      
        <ng-container matColumnDef="session">
          <th mat-header-cell *matHeaderCellDef> Session </th>
          <!-- <td mat-cell *matCellDef="let element; let i = index"> 
            <mat-form-field style="padding-bottom: 4px !important;" class="mt-10">
                <mat-label><span style="color: red">*</span> Select Session</mat-label>
                <mat-select formControlName="sessionId" (selectionChange)="onSessionChange($event, i)" required>
                  <mat-option *ngFor="let option of sessions"
                              [value]="option?.id">
                              {{option?.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field> 
          </td> -->
          <td mat-cell *matCellDef="let element; let i = index"> 
            {{ element.sessionName }} <br>
            {{ element.startTimeDisplay | date : 'hh:mm a' }} - {{ element.endTimeDisplay | date : 'hh:mm a' }}
          </td>
        </ng-container>

        
        <ng-container matColumnDef="funding">
            <th mat-header-cell *matHeaderCellDef> 
              <mat-checkbox [(ngModel)]="fundingCheck" [disabled]="validityType == 'nonTerm' || type == 'view' || type == 'view-logs'" (change)="onCheckboxChange($event, 'allowFunding')"
                            [checked]="getCheckedValue('allowFunding')">
              </mat-checkbox> 
              <span>Funding</span>
            </th>
            <td class="small" mat-cell *matCellDef="let element; let i = index">             
                <mat-form-field style="margin-top: 10px !important;" class="mt-10">
                    <!-- <mat-label>Room</mat-label> -->
                    <!-- <mat-label>Yes/No</mat-label> -->
                    <mat-select [(ngModel)]="element.allowFunding" [name]="'funding'+i" [disabled]="validityType == 'nonTerm' || type == 'view' || type == 'view-logs'" required>
                      <mat-option value="yes">
                        Yes
                      </mat-option>
                      <mat-option value="no">
                        No
                      </mat-option>
                    </mat-select>
                </mat-form-field> 
            </td>
        </ng-container>



        <ng-container matColumnDef="discount">
            <th mat-header-cell *matHeaderCellDef> 
              <mat-checkbox [(ngModel)]="discountCheck" [disabled]="type == 'view' || type == 'view-logs'" (change)="onCheckboxChange($event, 'allowDiscount')"
                            [checked]="getCheckedValue('allowDiscount')">
              </mat-checkbox> 
              <span>Discount </span>
            </th>
            <td mat-cell *matCellDef="let element; let i = index"> 
                <mat-form-field style="margin-top: 10px !important;" class="mt-10">
                    <!-- <mat-label>Yes/No</mat-label> -->
                    <mat-select [(ngModel)]="element.allowDiscount" [disabled]="type == 'view' || type == 'view-logs'" [name]="'discount'+i" required>
                      <mat-option value="yes">
                        Yes
                      </mat-option>
                      <mat-option value="no">
                        No
                      </mat-option>
                    </mat-select>
                </mat-form-field> 
            </td>
        </ng-container>



         <ng-container matColumnDef="addOns">
          <th mat-header-cell *matHeaderCellDef> Add-ons </th>
          <td mat-cell *matCellDef="let element; let i = index"> 
            <mat-form-field style="margin-top: 10px !important;" class="mt-10">
                <!-- <mat-label><span style="color: red">*</span> Room</mat-label> -->
                <mat-label>Please select</mat-label>
                <mat-select [(ngModel)]="element.addOnsIds" [disabled]="type == 'view' || type == 'view-logs'" [name]="'addOns'+i" multiple>
                  <mat-option *ngFor="let addOn of addOns" [value]="addOn?.id">
                       {{addOn?.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field> 
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>

<div class="top-heading text-bold" style="margin-top: 13px">
  Invoice To
</div>

<div>
  <form [formGroup]="Form" (ngSubmit)="onSubmitClick()">
    <div fxLayout="row wrap"
    fxLayoutAlign="start center"
    fxLayoutGap="15px"
    class="row-card-margins">
     <div fxFlex="22"
          style="gap: 3px;"
          class="text-small text-gray">
          Parent/Guardian

         <mat-form-field style="margin-top: 10px;">
             <input type="text"
             placeholder="Select Guardian"
             aria-label="Number"
             matInput
             style="width: 125px;"
             [formControl]="Form.get('guardian')"
             [matAutocomplete]="auto">
             <mat-autocomplete autoActiveFirstOption (optionSelected)="setGuardianData()" #auto="matAutocomplete">
                 <mat-option *ngFor="let option of filteredGuardians" [value]="option" [disabled]="option.name == Form.get('secondGuardian').value">
                   {{option.name}}
                 </mat-option>
             </mat-autocomplete>
             <div style="position: absolute;right: 3px;bottom: 6px;">
                <mat-icon [matTooltip]="selectedGuardian?.name +'  |  ' + (selectedGuardian?.type == 'primary' ? 'Primary ' : 'Secondary ') + 'parent guardian'" 
                          [matTooltipDisabled]="selectedGuardian == null"
                          class="cp" style="font-size: 20px;color: #00AFBB;">info</mat-icon>
             </div>
             <mat-error *ngIf="getField('guardian')">Guardian is Required</mat-error>
         </mat-form-field>

     </div>

     <div fxFlex="50"
         style="gap: 3px;"
         class="text-small text-gray">
     <span>Address</span>
     <mat-form-field style="margin-top: 10px;">
     <input matInput
             type="text"
             readonly
             formControlName="address1"
             required />
     <!-- <mat-error *ngIf="getField('address')">{{getErrorMessage('address')}}</mat-error> -->
     </mat-form-field>
     </div>

     <div fxFlex="22"
          style="gap: 3px;"
          class="text-small text-gray">
     Amount paid by this payee
    <mat-form-field style="margin-top: 10px;">
    <input matInput
            type="number"
            min="1"
            max="100"
            formControlName="amount1"
            (blur)="onBlurEvent($event,'amount1')"
            [readonly]="!specifiySecondPayee"
            required />
            <span matSuffix>%&nbsp;</span>
    <mat-error *ngIf="getField('amount1')">Amount is Required</mat-error>
    </mat-form-field>
    </div>
    </div>

    <div style="margin-top: 6px;" class="ml-10">
      <mat-checkbox [(ngModel)]="specifiySecondPayee" [ngModelOptions]="{standalone: true}" (ngModelChange)="onCheckChange()" [disabled]="allGuardians?.length == 1" style="margin-left: 5px; margin-bottom: 5px;">
        Specify second payee for this Booking</mat-checkbox>
    </div>

    <div *ngIf="specifiySecondPayee" fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayoutGap="15px"
        class="row-card-margins">
     <div fxFlex="22"
          style="gap: 3px;"
          class="text-small text-gray">
          Parent/Guardian 

         <mat-form-field style="margin-top: 10px;">
             <input type="text"
             placeholder="Select Guardian"
             aria-label="Number"
             style="width: 125px;"
             matInput
             [formControl]="Form.get('secondGuardian')"
             [matAutocomplete]="auto2">
             <mat-autocomplete autoActiveFirstOption (optionSelected)="setSecondGuardianData()" #auto2="matAutocomplete">
                 <mat-option *ngFor="let option of filteredSecondGuardians" [value]="option" [disabled]="option.name == Form.get('guardian').value">
                   {{option.name}}
                 </mat-option>
             </mat-autocomplete>
             <mat-error *ngIf="getField('secondGuardian')">Guardian is Required</mat-error>
             <div style="position: absolute;right: 3px;bottom: 6px;">
              <mat-icon [matTooltip]="selectedSecondGuardian?.name +'  |  ' + (selectedSecondGuardian?.type == 'primary' ? 'Primary ' : 'Secondary ') + 'parent guardian'" 
                        [matTooltipDisabled]="selectedSecondGuardian == null" class="cp" style="font-size: 20px;color: #00AFBB;">info</mat-icon>
             </div>

         </mat-form-field>

     </div>

     <div fxFlex="50"
         style="gap: 3px;"
         class="text-small text-gray">
      <span>Address</span>
     <mat-form-field style="margin-top: 10px;">
     <input matInput
             type="text"
             readonly
             formControlName="address2"
             />
     <!-- <mat-error *ngIf="getField('address')">{{getErrorMessage('address')}}</mat-error> -->
     </mat-form-field>
     </div>

     <div fxFlex="22"
          style="gap: 3px;"
          class="text-small text-gray">
      Amount paid by this payee
        <mat-form-field style="margin-top: 10px;">
        <input matInput
              type="number"
              min="1"
              max="100"
              formControlName="amount2"
              (blur)="onBlurEvent($event,'amount2')"
              required />
              <span matSuffix>%&nbsp;</span>
        <mat-error *ngIf="getField('amount2')">Amount is Required</mat-error>
        </mat-form-field>
     </div>
    </div>
  </form>
</div>



<div *ngIf="type != 'view' && type != 'view-logs'" fxLayout="row wrap"
     fxLayoutAlign="start center"
     fxLayoutGap="5px"
     style="height:100px">
    <div fxFlex="10">
    <button mat-button
            style="width: 90%;background-color: rgb(193, 187, 185);"
            type="button"
            class="btn-custom-form"
            (click)="onCancelClick()">Cancel</button>
    </div>
    <div fxFlex="10">
    <button mat-button
            style="width: 90%;"
            class="btn-custom-form yellow"
            type="submit"
            (click)="onSubmitClick()">Okay</button>
    </div>
</div>