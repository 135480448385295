<mat-card *ngIf="!isStartEnd" style="box-shadow: none !important;padding: 0 !important;">
  <mat-card-header>
    <mat-card-title>Select Time</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ngx-mat-timepicker [(ngModel)]="time"
                        name="time"
                        (focusout)="onTimeChange()"
                        ngDefaultControl
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="disabled"
                        [showSpinners]="showSpinners"
                        [stepHour]="stepHour"
                        [stepMinute]="stepMinute"
                        [enableMeridian]="enableMeridian"
                        [stepSecond]="stepSecond"
                        [showSeconds]="showSeconds">
    </ngx-mat-timepicker>
  </mat-card-content>
  <mat-card-actions>
    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="10px" style="padding: 0px 34px;">

      <div fxFlex>
        <button type="button"
                (click)="onCancel()"
                mat-button
                style="background-color: #E2AF2A;"
                class="btn-custom-form">
          Cancel
        </button>
      </div>
      <div fxFlex>
        <button (click)="onSelectTime()"
                type="button"
                mat-button
                class="btn-custom-form">
          Select
        </button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>

<mat-card *ngIf="isStartEnd" style="box-shadow: none !important;padding: 0 !important;">
  <!-- <mat-card-header>
    <mat-card-title>Select Time</mat-card-title>
  </mat-card-header> -->
  <mat-card-content>
    <h2> <strong>Start Time</strong> </h2>
    <ngx-mat-timepicker [(ngModel)]="startTime"
                        name="time"
                        (focusout)="onStartEndTimeChange('start')"
                        ngDefaultControl
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="disabled"
                        [showSpinners]="showSpinners"
                        [stepHour]="stepHour"
                        [stepMinute]="stepMinute"
                        [enableMeridian]="enableMeridian"
                        [stepSecond]="stepSecond"
                        [showSeconds]="showSeconds">
    </ngx-mat-timepicker>
  </mat-card-content>
  <mat-card-content>
    <h2> <strong>End Time</strong> </h2>
    <ngx-mat-timepicker [(ngModel)]="endTime"
                        name="time"
                        (focusout)="onStartEndTimeChange('end')"
                        ngDefaultControl
                        [ngModelOptions]="{standalone: true}"
                        [disabled]="disabled"
                        [showSpinners]="showSpinners"
                        [stepHour]="stepHour"
                        [stepMinute]="stepMinute"
                        [enableMeridian]="enableMeridian"
                        [stepSecond]="stepSecond"
                        [showSeconds]="showSeconds">
    </ngx-mat-timepicker>
  </mat-card-content>
  <mat-card-actions>
    <div fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="10px" style="padding: 0px 34px;">

      <div fxFlex>
        <button type="button"
                (click)="onCancel()"
                mat-button
                style="background-color: #E2AF2A;"
                class="btn-custom-form">
          Cancel
        </button>
      </div>
      <div fxFlex>
        <button (click)="onSelectTime()"
                type="button"
                mat-button
                class="btn-custom-form">
          Select
        </button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>

<!-- <div style="display: flex; gap: 10px;">
  <div>
    <input class="custom-timer-inputs" type="number" id="hours" min="0" max="13" maxlength="2" [(ngModel)]="hours" (ngModelChange)="onChange('hour')" (blur)="onTimeChangee('hours')" (input)="formatInput($event.target)">
  </div> :
  <div>
    <input class="custom-timer-inputs" type="number"  id="minutes" min="-1" max="60" maxlength="2" [(ngModel)]="minutes" (ngModelChange)="onChange('minutes')" (blur)="onTimeChangee('minutes')" (input)="formatInput($event.target)">
  </div>

  <div class="ampm">
    <div class="am" (click)="setAmPm('AM')">
      AM
    </div>
    <div class="pm" (click)="setAmPm('PM')">
      PM
    </div>
  </div>
</div> -->
