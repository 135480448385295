<div class="dialog-container">
    <mat-icon [ngStyle]="{ 'color': '#FFAB00' }" class="icon-class" style="font-size: 25px; cursor: pointer; vertical-align: middle;">error_outline</mat-icon>
</div>
<hr>
<div style="text-align: center;" *ngIf="!isSplitInvoice">
    <span id="invoice-title">Invoice {{invoice?.id}}</span>
    <p>A credit note will be generated of £{{invoice?.amountAfterDiscount}} for invoice no {{invoice?.id}}</p>
</div>
<div style="text-align: center;" *ngIf="isSplitInvoice">
    <span id="invoice-title">Invoice No {{invoice?.id}} and {{splitInvoice?.id}}</span>
    <p>The system will generate 2 credits of amount £{{invoice.amountAfterDiscount}} and £{{invoice.amountAfterDiscount}} against invoice no {{invoice?.id}} and {{splitInvoice?.id}}</p>
</div>
<div class="btn-container">
    <button
       (click)="onCancel()"
       class="btn-cancel">
            Cancel
      </button>
      <button  
            (click)="onConfirm()"
            class="btn-confirm">
            Confirm
      </button>
</div>
