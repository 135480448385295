<!-- [ngClass]="headerProps?.hasButton && !headerProps?.hasHeading ? 'onlyButton' " -->
<div fxLayout="row wrap"
     style="margin:10px 0px 10px;"
     [style.margin]="headerProps?.margin"
     [style.padding]="headerProps?.padding"
     fxLayoutAlign="space-between center"
     fxLayoutGap="5px">
    <div *ngIf="headerProps?.hasHeading"
         [fxFlex]="headingLabelDivWidth"
         style="font-weight: bold;">
        <button *ngIf="headerProps?.showBack"
                mat-icon-button
                (click)="goback()">
            <mat-icon class="mat-18"
                      style="color: #798CFF;">
                arrow_back
            </mat-icon>
        </button>
        <span [class]="headerProps?.textclass"
              [style.color]='headerProps?.colortype'
              [style.margin-left]="headerProps?.labelMargin">
            {{headerProps?.headingLabel}}
        </span>
    </div>
    <div fxFlex
         *ngIf="headerProps?.hasFilters">
        <!-- <ng-container *ngFor="let item of headerProps?.ActionButtons"> -->
            <filter-control
                            (filnalFilters)="filtersChange($event)"
                            [hasSearch]="true"
                            [hasToggle]="hasToggle"
                            [isTwoRows]="isTwoRowsFilter"
                            [screen]="currentScreen"
                            [fxFlexInSort]="fxFlexInSortForFilter"
                            [fxFlexInSearch]="fxFlexInSearchForFilter"
                            [searchConfig]="headerProps?.searchConfig"
                            [builtInFilters]="headerProps?.builtInFilters"
                            [filterArray]="filterArray"></filter-control>
        <!-- </ng-container> -->
    </div>
    <div *ngIf="showLegends" fxFlex>
        <div class="inline" style="margin-top: 19px;margin-right: 22px;">
            <div style="border-radius: 50%;width: 20px;height: 20px;margin-top: 1px;" [ngStyle]="{'background': legendColor}"></div>
            <div class="ml-10">
                <span> {{ legendLabel }} </span>
            </div>
          </div>
    </div>

    <div fxFlex
         [fxFlex]="headerProps?.buttonFlex"
         *ngIf="headerProps?.hasButton">
        <ng-container *ngFor="let item of headerProps?.ActionButtons">
            <button (click)="onTableHeaderButton(item)"
                    class="header-button"
                    style="float:none"
                    [style.margin]="headerProps?.buttonsMargin"
                    [style.margin-left]="item?.buttonLeftMargin"
                    [style.float]="headerProps?.float"
                    [style.background-color]="item.borderButton ? 'white' :item?.color"
                    [style.color]="item.borderButton ? item?.color: 'white'"
                    [style.border]="item.borderButton ? '2px solid': 'none'"
                    [disabled]="item.disabled ? true : false"
                    mat-raised-button
                    color="primary" *ngIf="item.visibility">{{item.buttonLabel}}</button>
        </ng-container>
    </div>

    <div fxFlex="28"
         fxFlex.xs="100"
         fxFlex.sm="70"
         *ngIf="headerProps?.hasRightLabel">
        <span class="text-normal text-color"
              style="float: right;">
            {{headerProps?.rightLabel}}
        </span>
    </div>
</div>
<!--  <button class="header-button"
                style="float: none;"
                (click)="onTableHeaderButton()"
                mat-button>{{headerProps?.buttonLabel}}</button> -->
